
  import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DefaultDialog: () => import(/* webpackChunkName: "default-dialog" */'@/components/core/RemoveModelDialog.vue'),
  },
})
  export default class RemoveLinkedModel extends Vue {
  @Prop({ type: String, required: true }) name!: string
  @Prop({ type: Number }) id?: string

  get component () {
    const { name, id } = this

    return {
      name: 'default-dialog',
      props: {
        id,
        model: name,
      },
    }
  }
  }
